import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/digital-usecase.scss'
import ArrowRight from '../images/icons/arrow-right.svg'
import DeltechImg from '../images/digital-usecase/delware-tech/bg-hero.png'
import { Link } from 'gatsby'

const DigitalUsecase = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="digital-usecase py-5">
                <div className="hero-section">
                    <div className="h-100 d-flex align-items-end">
                        <div className="container px-5">
                            <div className="row mb-5 pb-5 px-4">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div className="d-md-block d-none" style={{ borderBottom: '3px solid #E41700' }}></div>
                                        </div>
                                        <div className="col-md-11">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '3rem' }}><span className="text-red">Digital</span></h1>

                                            <p className="text-white mb-0" style={{ fontSize: '1.5rem' }}>Digitize your business operations and be prepared to thrive in the digital market.</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-1"></div>

                                <div className="col-md-4 my-auto">
                                    <p className="small off-white  mb-0" style={{ lineHeight: '22px' }}>Let us help your business go digital the right way! From your business models to your customer-facing touchpoints, we will work with you on digital strategies that will ensure you create new business values that will delight consumers. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container mt-5 pt-5">
                    <div className="my-md-5 py-md-5">
                        <h1 className="big-text text-center font-weight-bold mb-5 pb-5 mt-5 px-md-5 px-0">Here’s what we have <span className="text-red">done</span> for <span className="text-red">businesses</span> just like yours…</h1>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="deltech-bg">
                                <img src={DeltechImg} alt="background" />
                            </div>

                            <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">Digital strategy</span>consultancy</p>

                            <Link to="/delware-tech"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>Designing a digital transformation road map</h4></Link>

                            <Link to="/delware-tech"><p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DigitalUsecase